<script>
import OmniDialog from "@/components/OmniDialog.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import FileUploadingGrid from "@/components/FileUploadingGrid.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { OrderStopType } from "@/data/order";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import BaseView from "@/views/BaseView.vue";

export default {
  name: "PickupInfoDialog",
  components: { FileUploadingGrid, OmniSelect, TimePicker, DatePicker, OmniDialog },
  mixins: [ValidationMixin, DateTimeMixin, BaseView],
  props: {
    title: String,
    modelValue: Boolean,
    preload: Boolean,
    readonly: Boolean,
    trip: Object,
    route: Object,
    stop: Object,
  },
  emits: ["update:modelValue", "save"],
  data() {
    return {
      loading: false,
      loadData: {
        bolNumbers: [""],
        actualFreights: [],
        files: [],
        date: null,
        time: null,
        timezone: null,
      },
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    freights() {
      return this.uniqueArray(
        this.route.freights.filter((f) => this.stop.freights.includes(f.id)),
        ["id"],
      );
    },
    stopFreights() {
      return this.stop.freights.map((sf) => this.freights.find((f) => f.id === sf)).filter((f) => f !== undefined);
    },
    actualPalletsQuantity() {
      return this.loadData.actualFreights.reduce((sum, f) => {
        if (f.type === "pl") sum += parseInt(f.quantity) || 0;
        return sum;
      }, 0);
    },
    palletsQuantity() {
      return this.freights.reduce((sum, f) => {
        if (f.type === "pl") sum += parseInt(f.plannedQuantity) || 0;
        return sum;
      }, 0);
    },
    actualPiecesQuantity() {
      return this.loadData.actualFreights.reduce((sum, f) => {
        if (f.type === "pc") sum += parseInt(f.quantity) || 0;
        return sum;
      }, 0);
    },
    piecesQuantity() {
      return this.freights.reduce((sum, f) => {
        if (f.type === "pc") sum += parseInt(f.plannedQuantity) || 0;
        return sum;
      }, 0);
    },
    actualFreightsWeight() {
      return this.loadData.actualFreights.reduce((sum, f) => sum + (parseInt(f.weight) || 0), 0);
    },
    actualPalletsQuantityClass() {
      if (this.actualPalletsQuantity > this.palletsQuantity) {
        return "text-error";
      } else if (this.actualPalletsQuantity < this.palletsQuantity) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    actualPiecesQuantityClass() {
      if (this.actualPiecesQuantity > this.piecesQuantity) {
        return "text-error";
      } else if (this.actualPiecesQuantity < this.piecesQuantity) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    actualFreightsWeightClass() {
      if (this.actualFreightsWeight > this.freightsWeight) {
        return "text-error";
      } else if (this.actualFreightsWeight < this.freightsWeight) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    freightsQuantity() {
      const result = [];
      if (this.palletsQuantity > 0) {
        result.push(this.palletsQuantity + " plt");
      }
      if (this.piecesQuantity > 0) {
        result.push(this.piecesQuantity + " pcs");
      }
      return result.join(", ");
    },
    freightsWeight() {
      return this.freights.reduce((sum, f) => sum + parseInt(f.plannedWeight), 0);
    },
    canRemoveBolNumber() {
      return this.loadData.bolNumbers.length > 1;
    },
  },
  watch: {
    async showDialog(value) {
      if (value) {
        this.loadData.bolNumbers = this.preload ? Array.from(new Set(this.stopFreights.map((f) => f.bolNumber))) : [""];
        let freightNumber = 1;
        this.loadData.actualFreights = this.stopFreights.map((f) => ({
          ...f,
          weight: f.weight || f.plannedWeight,
          quantity: f.quantity || f.plannedQuantity,
          number: freightNumber++,
        }));
        if (this.preload) {
          this.loadData.timezone = this.stop.timezone;
          const date = this.$dayjs(this.stop.loadUnloadTime).tz(this.stop.timezone);
          this.loadData.date = date.format("YYYY-MM-DD");
          this.loadData.time = date.format("HH:mm");

          const filesResp = await this.$api.trips.getTripFiles(this.trip.id);
          if (filesResp.success) {
            this.loadData.files = filesResp.data
              .filter((f) => f.tags.includes("route_stop:" + this.stop.id))
              .map((f) => ({
                ...f,
                previewPath: this.getPreviewPath(f),
                bol: f.tags.find((t) => t.startsWith("bol:"))?.split(":")[1],
              }));
          }
        } else {
          this.loadData.timezone = this.stop.facility.timezone;
          const date = this.$dayjs().tz(this.loadData.timezone);
          this.loadData.date = date.format("YYYY-MM-DD");
          this.loadData.time = date.format("HH:mm");
        }
      }
    },
  },
  methods: {
    addBolNumber() {
      this.loadData.bolNumbers.push("");
    },
    removeBolNumber(index) {
      this.loadData.bolNumbers.splice(index, 1);
    },
    getFreightDeliveryPoint(freight) {
      const facility = this.route.routeStops.find(
        (o) => o.type === OrderStopType.delivery && o.freights.includes(freight.id),
      )?.facility;
      return facility ? facility.addressLine + ", " + facility.address : null;
    },
    getFreightData(freight) {
      return freight.plannedQuantity + " " + this.getFreightType(freight) + ", " + freight.plannedWeight + " lbs";
    },
    getFreightType(freight) {
      if (freight.type === "pl") return "plt";
      return "pcs";
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (!valid || this.loadData.files.length === 0) return;
      this.loading = true;
      for (const uploadingFile of this.loadData.files) {
        uploadingFile.loading = true;
        const fileResponse = await this.$api.trips.uploadRouteStopFile(
          this.trip.id,
          this.stop.id,
          "bol",
          uploadingFile.file,
          uploadingFile.bol,
        );
        if (fileResponse.success) {
          Object.assign(uploadingFile, fileResponse.data);
        } else {
          uploadingFile.error = true;
        }
        uploadingFile.loading = false;
      }
      const response = await this.$api.trips.loadRouteStop(this.trip.id, this.stop.id, {
        bols: this.loadData.bolNumbers.map((number) => ({
          number,
          files: this.loadData.files.filter((file) => file.bol === number),
        })),
        loadTime: this.dateToUTC(this.loadData.date + " " + this.loadData.time, this.loadData.timezone),
        freights: this.loadData.actualFreights,
      });
      if (response.success) {
        this.$emit("save");
        this.showDialog = false;
      } else {
        this.showSnackbarError("Error load verification at stop");
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    :disabled="loading"
    :loading="loading"
    :primary-action-label="readonly ? null : 'Save'"
    :title="title"
    size="xlarge"
    @primary-action:click="save"
  >
    <v-form ref="form">
      <div class="mb-6">
        <div class="font-size-16 font-weight-500 mb-6">BOL info</div>
        <div v-for="(_, i) in loadData.bolNumbers" :key="i" class="mb-4 d-flex">
          <v-text-field
            v-model="loadData.bolNumbers[i]"
            :disabled="readonly"
            :rules="[requiredValidator]"
            class="required"
            color="primary"
            data-qa="bol-number-field"
            density="compact"
            hide-details="auto"
            label="BOL number"
            variant="outlined"
          />
          <v-btn
            v-if="canRemoveBolNumber && !readonly"
            class="ml-2"
            color="error"
            data-qa="remove-bol-button"
            icon="mdi-delete-outline"
            size="small"
            variant="text"
            @click="removeBolNumber(i)"
          ></v-btn>
        </div>
        <v-btn
          v-if="!readonly"
          class="font-size-11 font-weight-600 text-uppercase"
          color="primary"
          data-qa="add-bol-button"
          density="compact"
          prepend-icon="mdi-plus"
          variant="text"
          @click="addBolNumber"
        >
          Add BOL
        </v-btn>
      </div>
      <div class="mb-6">
        <div class="font-size-16 font-weight-500 mb-4">Files<span class="text-error">*</span></div>
        <file-uploading-grid v-model="loadData.files" :disabled="readonly" required>
          <template #footer="{ file }">
            <omni-select
              v-if="file"
              v-model="file.bol"
              :disabled="readonly"
              :items="loadData.bolNumbers"
              :rules="[requiredValidator]"
              class="mt-5"
              data-qa="bol-select-file-zone"
              label="BOL"
              required
            ></omni-select>
          </template>
        </file-uploading-grid>
      </div>
      <div class="mb-6">
        <div class="font-size-16 font-weight-500 mb-4">Freights at stop:</div>
        <div v-for="actualFreight in loadData.actualFreights" :key="actualFreight.id">
          <div class="bg-grey-lighten-4 pa-4">
            <div class="text-grey-darken-3 font-weight-500 mb-2">Freight#{{ actualFreight.number }}:</div>
            <div class="d-flex align-center justify-space-between">
              <div>
                <span class="text-grey-darken-2 font-weight-500">Planned: </span>
                <span class="text-grey-darken-3 font-weight-500" data-qa="freight-block-planned"
                  >#{{ actualFreight.number }}: {{ getFreightData(actualFreight) }}</span
                >
              </div>
              <div class="d-flex align-center text-grey-darken-1">
                <v-icon class="mr-2" color="error" icon="mdi-map-marker-radius-outline" size="18"></v-icon>
                #{{ actualFreight.number }}: {{ getFreightDeliveryPoint(actualFreight) }}
              </div>
            </div>
          </div>
          <div class="bg-grey-lighten-5 pa-4">
            <div class="text-grey-darken-3 font-weight-500 mb-3">Actual:</div>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="actualFreight.quantity"
                  :disabled="readonly"
                  :rules="[requiredValidator, digitsValidator]"
                  class="required bg-white"
                  color="primary"
                  data-qa="quantity-field"
                  density="compact"
                  hide-details="auto"
                  label="Quantity"
                  variant="outlined"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="actualFreight.weight"
                  :disabled="readonly"
                  :rules="[requiredValidator, digitsValidator]"
                  class="required bg-white"
                  color="primary"
                  data-qa="total-weight-field"
                  density="compact"
                  hide-details="auto"
                  label="Total weight"
                  variant="outlined"
                />
              </v-col>
              <v-col>
                <omni-select
                  v-model="actualFreight.bolNumber"
                  :disabled="readonly"
                  :items="loadData.bolNumbers"
                  :rules="[requiredValidator]"
                  class="bg-white"
                  data-qa="actual-freight-bol-select"
                  label="BOL"
                  required
                ></omni-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="actualFreight.sealNumber"
                  :disabled="readonly"
                  class="bg-white"
                  color="primary"
                  data-qa="seal-number-field"
                  density="compact"
                  hide-details="auto"
                  label="Seal number"
                  variant="outlined"
                />
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="actualFreight.differentAddress"
                  :disabled="readonly"
                  class="text-grey-darken-2"
                  color="primary"
                  data-qa="different-address-checkbox"
                  density="compact"
                  hide-details
                  label="Different address"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div>
          <div class="text-grey-darken-3 font-weight-500 mt-4 mb-2 text-right" data-qa="total-planned-freigth">
            Planned total: {{ freightsQuantity }}, {{ freightsWeight }} lbs
          </div>
          <div class="font-weight-500 text-grey-darken-3 text-right" data-qa="total-freight">
            Total:
            <span :class="actualPalletsQuantityClass">{{ actualPalletsQuantity }}</span> plt,
            <span :class="actualPiecesQuantityClass">{{ actualPiecesQuantity }}</span> pcs,
            <span :class="actualFreightsWeightClass">{{ actualFreightsWeight }}</span> lbs
          </div>
        </div>
      </div>
      <div>
        <div class="font-size-16 font-weight-500 mb-4">Time</div>
        <v-row>
          <v-col cols="3">
            <omni-select
              v-model="loadData.timezone"
              :disabled="readonly"
              :items="timezonesShortList"
              :rules="[requiredValidator]"
              data-qa="timezone"
              label="Time zone"
              required
            />
          </v-col>
          <v-col cols="6">
            <date-picker v-model="loadData.date" :disabled="readonly" label="Date" required></date-picker>
          </v-col>
          <v-col cols="3">
            <time-picker v-model="loadData.time" :disabled="readonly" label="Time" required></time-picker>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
